import * as React from "react"
import { Row, Col, Card } from "react-bootstrap"
import { Link } from "@reach/router";

import {ImageModule} from '../../modules/Image_Module'

import {propertyDetailsLink} from '../common/propertyUtils'

// Styles
import "./SinglePropertyCard.scss"

const SinglePropertyCard = ( { singlePropertyCardData = {}, showLabel } ) => {
	const { Label } = singlePropertyCardData;

	var link = propertyDetailsLink(singlePropertyCardData.department, singlePropertyCardData.search_type, singlePropertyCardData.slug, singlePropertyCardData.id);

    console.log("build_images", singlePropertyCardData?._id, singlePropertyCardData.images)

    var building_link = `/property-building/${singlePropertyCardData?.slug}-${singlePropertyCardData?._id}`;
    var disp_addr = singlePropertyCardData.display_address.replace(/,/g, ', ').replace(/ ,/g, ', ');

    var image_length = singlePropertyCardData?.images?.filter(item => item.field === "images");

	return (
		<div className="single-property-card-wrapper">
			<Card>
				<div className="card-container-image">
					{ showLabel && Label && (
						<span className="image-label">
							{ Label }
						</span>
					) }
					<div class="card-container-image">
						<Link to={ building_link }>
							<ImageModule classNames={"card-img-top"} ImageSrc={image_length?.length > 0 ? image_length[0] : ""} altText={disp_addr} />
						</Link>
					</div>
				</div>
				<Card.Body>
					<Card.Title className="card-title">
						<Link to={ building_link }>{ disp_addr }</Link>
					</Card.Title>
					<Row className="card-content">
						{singlePropertyCardData.price !== null && singlePropertyCardData.price != 0 &&
						<div className="card-subtitle">
							£{ singlePropertyCardData.price.toLocaleString() } p/w
						</div>
						} 
						
						{ <Col>
							<div className="card-footer-icons">
								<div className="count-icons">
									<i className="icon icon-bedroom"></i>
									<span>{ singlePropertyCardData.bedroom }</span>
								</div>
								<div className="count-icons">
									<i className="icon icon-bathroom"></i>
									<span>{ singlePropertyCardData.bathroom }</span>
								</div>
								<div className="count-icons">
									<i className="icon icon-pet-friendly"></i>
								</div>
							</div>
						</Col> }
					</Row>
				</Card.Body>
			</Card>
		</div>
	)
}

export default SinglePropertyCard
