import React, { useEffect, useState } from "react"
import { Row, Col } from 'react-bootstrap';

// Styles
import './PropertyCards.scss';

// Components
import {GetAPIData, Building_API} from "../common/agentAPI"
import SinglePropertyCard from '../SinglePropertyCard/BuildingCard';

const PropertyCards = ( { propertyCardsData, layout, showLabel = false, responsiveShowHide } ) => {
	const[buildList, setBuildingList] = useState([]);

	useEffect(() => {
        //var building_api = Building_API+"?request_from=website&_limit=-1";
        var building_api = Building_API+"?request_from=website&_sort=createdat:desc&_limit=9";
		console.log("building_api::", building_api);
        GetAPIData(building_api).then(async apiRes => {
            
            var building_list = [];
            if(apiRes?.success === true){
                apiRes?.results?.length > 0 && apiRes?.results.map((item) =>{
                    building_list.push(item);
                })
                setBuildingList(building_list)
            }
        });
    }, [])

	return (
		<div className="property-cards-wrapper">
			{
				buildList?.length > 0 && (
					<Row>
						{
							buildList.map( ( propertyDetails, propertyDetailsIndex ) => {
								
								const getClassName = ( propertyDetailsIndex ) => {
									if ( propertyDetailsIndex > 0 && propertyDetailsIndex < 2 ) {
										return 'hide-on-tab-and-mobile';
									} else if ( propertyDetailsIndex > 0 ) {
										return 'hide-on-mobile';
									}
									return '';
								}

								return (
									<Col
										key={ propertyDetailsIndex }
										{ ...layout }
										//className={ responsiveShowHide && getClassName( propertyDetailsIndex ) }
									>
										<SinglePropertyCard
											singlePropertyCardData={ propertyDetails.Properties || propertyDetails }
											showLabel={ showLabel }
										/>
									</Col>
								)

							} )
						}
					</Row>
				)
			}
		</div>
	);
};

export default PropertyCards;
